<template>
  <table class="not-hoverable">
    <thead>
      <th>Start Date</th>
      <th>Due Date</th>
      <th>Reg. No.</th>
      <th>Make & Model</th>
      <th>Mileage</th>
      <th>Ramp</th>
      <th class="description">Description</th>
      <th>Client</th>
      <th>In Queue</th>
      <th>Progress</th>
    </thead>
    <tbody> 
      <tr>
        <td>{{ new Date(booking.details.startDate).toLocaleString().slice(0, 17) }}</td>
        <td :class="{ tilted: !booking.details.dueDate }">{{ booking.details.dueDate ? new Date(booking.details.dueDate).toLocaleString().slice(0, 17) : 'Not Set' }}</td>
        <td>{{ booking.details.regNo }}</td>
        <td :class="{ tilted: !booking.details.make }">{{ booking.details.make || 'Unknown' }}</td>
        <td :class="{ tilted: !booking.details.miles }">{{ booking.details.miles || 'N/A' }}</td>
        <td>{{ booking.details.ramp }}</td>
        <td class="description" :class="{ tilted: !booking.details.description }">{{ booking.details.description || 'N/A' }}</td>
        <td><router-link :to="{ name: 'Client', params: { id: booking.client.id } }">{{ booking.client.name }}</router-link></td>
        <td v-if="!fetchLoad">
          <input type="checkbox" id="inQueue" v-model="booking.settings.inQueue" @change="(e) => handleChange(e, booking.id, booking.settings)" title="In Queue?">
        </td>
        <td v-else>⏳</td>
        <td v-if="!fetchLoad">
          <div class="checkboxes">
            <input type="checkbox" id="priceAgreed" v-model="booking.settings.priceAgreed" @change="(e) => handleChange(e, booking.id, booking.settings)" title="Price Agreed?">
            <input type="checkbox" id="partOrdered" v-model="booking.settings.partOrdered" @change="(e) => handleChange(e, booking.id, booking.settings)" title="Parts Ordered?">
            <input type="checkbox" id="inGarage" v-model="booking.settings.inGarage" @change="(e) => handleChange(e, booking.id, booking.settings)" title="In Garage?">
          </div>
        </td>
        <td v-else class="checkboxes">⏳</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import useFetch from '@/composables/useFetch'
import { Toast } from '@/composables/useSwal'

export default {
  props: ['booking'],
  setup() {
    const { error: fetchError, isLoading: fetchLoad, fetchData } = useFetch()

    const handleChange = async (e, bookingId, settings) => {
      await fetchData(`/bookings/${bookingId}`, 'patch', {
        settings: {
          ...settings,
          [ e.target.id ]: e.target.checked
        }
      })
      
      if (!fetchError.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Booking updated successfully'
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Error: ${fetchError.value}`
        })
      }
    }

    return { fetchLoad, handleChange }
  }
}
</script>