<template>
  <table class="not-hoverable">
    <thead>
      <th>Name</th>
      <th>Part Price</th>
      <th>Labour Price</th>
      <th>Total Price</th>
      <th style="max-width:50px;">Completed?</th>
    </thead>
    <div v-if="isLoadingServices" class="loading">Fetching Services... ⏳</div>
    <tbody v-else-if="!isLoadingServices && services"> 
      <tr v-for="service in services" :key="service.id">
        <td>{{ service.details.name }}</td>
        <td>£{{ service.prices.partPrice.toFixed(2) }}</td>
        <td>£{{ service.prices.labourPrice.toFixed(2) }}</td>
        <td>£{{ service.prices.totalPrice.toFixed(2) }}</td>
        <td><input type="checkbox" v-model="service.details.isCompleted" @change="(e) => handleChange(e, service.id)"></td>
        <td class="button-area">
          <button v-if="!isLoading" class="danger" @click="() => handleDelete(service.id)">Remove</button>
          <button v-else class="danger" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
        </td>
      </tr>
      <tr>
        <td><input type="text" v-model="service.details.name" placeholder="Service/Part Name" required></td>
        <td><input type="number" v-model="service.prices.partPrice" min="0" step=".01" placeholder="Part Price" required></td>
        <td><input type="number" v-model="service.prices.labourPrice" min="0" step=".01" placeholder="Labour Price" required></td>
        <td></td>
        <td></td>
        <td class="button-area">
          <button v-if="!isLoading" class="secondary" @click="handleSave">Add</button>
          <button v-else class="secondary" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
        </td>
      </tr>
    </tbody>
    <!-- <div v-else-if="!isLoadingServices && (!services || !services.length)" class="error">No Parts or Services have been added 🤷</div> -->
  </table>
</template>

<script>
import { ref } from 'vue'
import useFetch from '@/composables/useFetch'
import { Toast } from '@/composables/useSwal'

export default {
  props: ['services', 'bookingId', 'isLoadingServices'],
  setup(props) {
    const { error, isLoading, fetchData } = useFetch()

    const service = ref({
      details: {
        name: '',
      },
      prices: {
        partPrice: 0,
        labourPrice: 0,
        totalPrice: 0
      }
    })

    const handleSave = async () => {
      service.value.prices.totalPrice = service.value.prices.partPrice + service.value.prices.labourPrice

      await fetchData(`/services/${props.bookingId}`, 'post', service.value)
      
      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Service added successfully'
        })

        service.value = {
          details: {
            name: '',
          },
          prices: {
            partPrice: 0,
            labourPrice: 0,
            totalPrice: 0
          }
        }
      } else {     
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    const handleChange = async (e, serviceId) => {
      await fetchData(`/services/${props.bookingId}/${serviceId}`, 'patch', {
        'details.isCompleted': e.target.checked
      })

      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Service updated successfully'
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Error: ${error.value}`
        })
      }
    }
    
    const handleDelete = async (serviceId) => {
      await fetchData(`/services/${props.bookingId}/${serviceId}`, 'delete')

      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Service deleted successfully'
        })
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    return { service, handleSave, handleChange, handleDelete, isLoading }
  }
}
</script>

<style scoped>
  .loading {
    font-size: 15px;
    margin: 15px 0;
  }
</style>