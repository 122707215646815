<template>
  <div class="modal show">
    <div class="inner-modal">
      <div v-if="mode !== 'edit'" class="title">Create New Invoice/Estimate 🧾</div>    
      <div v-else class="title">Edit Invoice/Estimate 🧾</div>
      <form @submit.prevent="handleSave">
        <label>Invoice/Estimate Number:</label>
        <input type="text" v-model="invoice.details.invoiceNo" placeholder="Optional">
        <label>Issue Date:</label>
        <input type="date" :value="invoice.details.issueDate ? new Date(invoice.details.issueDate).toISOString().substring(0, 10) : null" @change="(e) => handleDate('issue', e.target.value)" required>
        <label>Due Date:</label>
        <input type="date" :value="invoice.details.dueDate ? new Date(invoice.details.dueDate).toISOString().substring(0, 10) : null" @change="(e) => handleDate('due', e.target.value)" required>
        <div v-if="mode !== 'edit'" >
          <label>Services/Parts:</label>
          <InputSearch :services="services" :selectedServices="invoice.services" @added-service="handleService" @removed-service="handleRemove" @add-service="handleAdd" />
        </div>
        <label>Discount (in %):</label>
        <input type="number" v-model="invoice.prices.discount" min="0" max="100" required>
        <label>Tax (in %):</label>
        <input type="number" v-model="invoice.prices.tax" min="0" max="100" required>
        <label>Is Estimate?</label>
        <input type="checkbox" v-model="invoice.details.isEstimate">
        <div v-if="mode === 'edit'">
          <label>Status:</label>
          <select v-model="invoice.details.status">
            <option value="open">Open</option>
            <option value="sent">Sent</option>
            <option value="paid">Paid</option>
            <option value="partiallyPaid">Partially Paid</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
        <div class="buttons">
          <button v-if="!isLoading" type="submit" class="swal2-styled swal2-confirm">Save</button>
          <button v-if="!isLoading" type="button" class="swal2-styled swal2-cancel" @click="handleClose">Close</button>
          <button v-else class="swal2-styled swal2-confirm" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import useFetch from '@/composables/useFetch'
import { Toast } from '@/composables/useSwal'
import InputSearch from '../Service/InputSearch.vue'

export default {
  emits: ['add-service', 'modal-close'],
  props: ['mode', 'invoice', 'bookingId', 'clientId', 'services'],
  components: { InputSearch },
  setup(props, { emit }) {
    const { error, isLoading, fetchData } = useFetch()
    const invoice = (props.mode === 'edit' && props.invoice) 
      ? ref(props.invoice) 
      : ref({
        details: {
          invoiceNo: null,
          number: null,
          issueDate: Date.now(),
          dueDate: null,
          status: 'open',  
          isEstimate: true
        },
        booking: {
          id: props.bookingId,
        },
        client: {
          id: props.clientId
        },
        prices: {
          parts: 0,
          labour: 0,
          subtotal: 0,
          total: 0,
          discount: 0,
          tax: 0  
        },
        services: props.services.map(service => {
          return {
            details: {
              name: service.details.name
            },
            prices: {
              labourPrice: service.prices.labourPrice,
              partPrice: service.prices.partPrice,
              totalPrice: service.prices.totalPrice,
            }
          }
        })
      })

    const handleDate = (date, value) => {
      if (date === 'issue') {
        invoice.value.details.issueDate = new Date(value).getTime()
      } else if (date === 'due') {
        invoice.value.details.dueDate = new Date(value).getTime()
      }
    }

    const handleService = (service) => {
      invoice.value.services = [...invoice.value.services, service]
    }

    const handleRemove = (serviceId) => {
      invoice.value.services = invoice.value.services.filter(el => el.id !== serviceId)
    }

    const handleSave = async () => {
      if (!invoice.value.services.length) {
        Toast.fire({
          icon: 'error',
          title: 'Hey there...',
          text: 'Please add some services'
        })

        return
      }

      invoice.value.prices.parts = 0
      invoice.value.prices.labour = 0
      invoice.value.prices.subtotal = 0

      invoice.value.services.forEach(el => {
        invoice.value.prices.parts += el.prices.partPrice          
        invoice.value.prices.labour += el.prices.labourPrice          
        invoice.value.prices.subtotal += el.prices.totalPrice          
      })

      invoice.value.prices.total = parseFloat(((parseFloat((invoice.value.prices.subtotal * ((100 - invoice.value.prices.discount) / 100)).toFixed(2))) * ((100 + invoice.value.prices.tax) / 100)).toFixed(2))
      
      if (props.mode !== 'edit') {
        await fetchData('/invoices', 'post', invoice.value)
      } else {
        await fetchData(`/invoices/${invoice.value.id}`, 'patch', invoice.value)
      }

      if (!error.value) {
        emit('modal-close')

        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: props.mode !== 'edit' ? 'Invoice created successfully' : 'Invoice updated successfully'
        })
      } else {     
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    const handleAdd = () => {
      emit('add-service')
    }

    const handleClose = () => {
      emit('modal-close')
    }

    return { invoice, handleDate, handleService, handleRemove,
      handleSave, handleAdd, handleClose, isLoading }
  }
}
</script>

<style scoped>
  .modal {
    z-index: 1800;
  }
  .inner-modal {
    min-width: 400px;
  }
</style>