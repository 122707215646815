<template>
  <div class="page-header">
    <h3>Manage Booking</h3>
    <div>
      <!-- <button @click="showServiceModal = true">New Part/Service</button> -->
      <button @click="showInvoiceModal = true">New Invoice</button>
      <button class="secondary" @click="showBookingModal = true">Edit Booking</button>
      <button class="danger" @click="handleDelete">Delete Booking</button>
    </div>
  </div>
  <div v-if="!isLoading && booking" class="container">
    <div class="inner-container">
      <div class="page-subtitle">Booking Details 🚗</div>
      <BookingDetails :booking="booking" />
    </div>
    <div class="inner-container">
      <div class="page-subtitle">Parts & Services 🛠️</div>
      <div class="total-amount">Total Amount: £{{ totalServiceAmount }}</div>
      <ServiceTable :services="services" :bookingId="id" :isLoadingServices="isLoading" />
    </div>
    <div class="inner-container">
      <div class="page-subtitle">Invoices 🧾</div>  
      <InvoiceTable :bookingId="booking.id" />
    </div>
  </div>
  <div v-else-if="isLoading" class="container">
    <div class="loading-documents">⏳ LOADING...</div>
  </div>
  <div v-else-if="error" class="container">
    <div class="loading-documents error">{{ error }}</div>
  </div>
  <div class="loading-page" :class="{ shown: fetchLoad }"><img src="@/assets/images/spinner.svg" alt="Loading..."></div>
  <BookingModal v-if="showBookingModal" :mode="'edit'" :booking="JSON.parse(JSON.stringify(booking))" @modal-close="showBookingModal = false" />
  <ServiceModal v-if="showServiceModal" :bookingId="id" @modal-close="showServiceModal = false" />
  <InvoiceModal v-if="showInvoiceModal" :bookingId="id" :clientId="booking.client.id" :services="services" @add-service="showServiceModal = true" @modal-close="showInvoiceModal = false" />
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import useFetch from '@/composables/useFetch'
import useFirestore from '@/composables/useFirestore'
import BookingDetails from '@/components/Booking/Details.vue'
import BookingModal from '@/components/Booking/Modal.vue'
import ServiceTable from '@/components/Service/Table.vue'
import ServiceModal from '@/components/Service/Modal.vue'
import InvoiceModal from '@/components/Invoice/Modal.vue'
import InvoiceTable from '@/components/Invoice/Table.vue'
import { Swal, Toast } from '@/composables/useSwal'

export default {
  props: ['id'],
  components: { BookingDetails, BookingModal, ServiceTable, ServiceModal, InvoiceModal, InvoiceTable },
  setup(props) {
    const router = useRouter()
    const { document: booking, documents: services, 
      error, isLoading, listenDocument, listenDocuments } = useFirestore()
    const { error: fetchError, isLoading: fetchLoad, fetchData } = useFetch()
    const showBookingModal = ref(false)
    const showInvoiceModal = ref(false)
    const showServiceModal = ref(false)

    listenDocument('bookings', props.id)
    listenDocuments('bookings', props.id, 'services', ['createdAt', 'desc'])

    const totalServiceAmount = computed(() => {
      if (services && services.value) {
        let totalAmount = 0
        services.value.forEach(el => totalAmount += el.prices.totalPrice)
        return totalAmount.toFixed(2)
      }
    })

    const handleDelete = async () => {
      Swal.fire({
        title: 'Uh-oh...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Delete Booking`,
        html: 
        `<h4>Deleting this Booking is irreversible and you'll lose all of its data, including related Invoices.</h4><br>
        <h4>Do you confirm?</h4></div>`
      }).then(async (result) => {
        if (result.isConfirmed) {
          await fetchData(`/bookings/${props.id}`, 'delete')

          if (!fetchError.value) {
            Toast.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Booking deleted successfully'
            })

            router.push({ name: 'Bookings' })
          } else {
            Toast.fire({
              icon: 'error',
              title: 'Oops...',
              text: `Error: ${fetchError.value}`
            })
          }   
        }
      })
    }

    return { booking, error, isLoading, fetchLoad, services, totalServiceAmount, 
      showBookingModal, showInvoiceModal, showServiceModal, handleDelete }
  }
}
</script>

<style scoped>
  .total-amount {
    float: right;
    margin: -20px 0 20px 0;
    font-weight: 600;
  }
</style>