<template>
  <div class="modal show">
    <div class="inner-modal">
      <div class="title">Add New Part/Service 🚗</div>    
      <form @submit.prevent="() => handleSave(false)">
        <label>Service/Part Name:</label>
        <input type="text" placeholder="Name of service/part" v-model="service.details.name" required>
        <label>Part Price (in £):</label>
        <input type="number" v-model="service.prices.partPrice" min="0" step=".01" required>
        <label>Labour Price (in £):</label>
        <input type="number" v-model="service.prices.labourPrice" min="0" step=".01" required>
        <div class="buttons">
          <button v-if="!isLoading" type="submit" class="swal2-styled swal2-confirm">Save</button>
          <button v-if="!isLoading" type="button" class="swal2-styled swal2-confirm" @click="() => handleSave(true)">Save & Add New</button>
          <button v-if="!isLoading" type="button" class="swal2-styled swal2-cancel" @click="handleClose">Close</button>
          <button v-else class="swal2-styled swal2-confirm" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import useFetch from '@/composables/useFetch'
import { Toast } from '@/composables/useSwal'

export default {
  props: ['bookingId'],
  emits: ['modal-close'],
  setup(props, { emit }) {
    const { error, isLoading, fetchData } = useFetch()
    const service = ref({
      details: {
        name: ''
      },
      prices: {
        partPrice: 0,
        labourPrice: 0,
        totalPrice: 0
      }
    })

    const handleSave = async (keepOpen) => {
      service.value.prices.totalPrice = service.value.prices.partPrice + service.value.prices.labourPrice

      await fetchData(`/services/${props.bookingId}`, 'post', service.value)
      
      if (!error.value) {
        if (!keepOpen) {
          emit('modal-close')
        } else {
          service.value = {
            details: {
              name: ''
            },
            prices: {
              partPrice: 0,
              labourPrice: 0,
              totalPrice: 0
            }
          }
        }

        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Service added successfully'
        })
      } else {     
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    const handleClose = () => {
      emit('modal-close')
    }

    return { service, handleSave, handleClose, isLoading }
  }
}
</script>