<template>
  <div class="select-client">
    <div class="search-wrap">
      <input type="text" @keydown.enter.prevent="handleSearch" @focus="showServiceList = true" placeholder="Search for Services">
      <div :class="{ shown: showServiceList }" class="clients-list">
        <span class="close-list" @click="showServiceList = false">&times;</span>
        <div v-for="service in servicesArr" :key="service.id" @click="() => handleService(service)" class="client-item text">
          {{ service.details.name }} - (Price: £{{ service.prices.totalPrice.toFixed(2) }})
        </div>
        <div v-if="servicesArr !== null && !servicesArr.length" class="text">No Services match searched term</div>
        <div v-if="servicesArr === null" class="text">Type in Service's Name</div>
      </div>
      <div v-for="service in selectedServices" :key="service.id" @click="() => handleRemove(service.id)" class="chosen-client">
        {{ service.details.name }}
      </div>
    </div>
    <button type="button" @click="handleAdd">+</button>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: ['services', 'selectedServices'],
  emits: ['added-service', 'removed-service', 'add-service'],
  setup({ services }, { emit }) {
    const servicesArr = ref(services)
    const showServiceList = ref(false)
    const searchTerm = ref(null)

    const handleSearch = (e) => {
      if (e.target.value) {
        servicesArr.value = services.filter(el => el.details.name.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1)
      } else {
        servicesArr.value = services
      }
    }
    
    const handleService = (service) => {
      emit('added-service', {
        details: {
          name: service.details.name
        },
        prices: {
          labourPrice: service.prices.labourPrice,
          partPrice: service.prices.partPrice,
          totalPrice: service.prices.totalPrice,
        }
      })
    }
    
    const handleRemove = (serviceId) => {
      emit('removed-service', serviceId)
    }

    const handleAdd = () => {
      emit('add-service')
    }

    return { servicesArr, showServiceList, searchTerm, 
      handleSearch, handleService, handleRemove, handleAdd }
  }
}
</script>